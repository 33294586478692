import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from '../views/Main.vue';
import HomeView from '../views/HomeView.vue';
import NotFound from '../views/NotFound.vue';
// import Abount from '../views/AboutView.vue';
Vue.use(VueRouter);
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [{
        path: '/',
        redirect: '/homeView',
        component: resolve => {
            require(['@/views/Main.vue'], resolve);
        },
        children: [{
                path: '/homeView',
                name: '1',
                component: resolve => {
                    require(['@/views/HomeView.vue'], resolve);
                },
            },
            // 解决方案
            {
                // path: '/solutions',
                // name: '6-4',
                // component: resolve => {
                //     require(['@/views/solutions/index.vue'], resolve);
                // },
                // children: [{
                // path: '/solutions/govment', //智慧政务
                // name: '6-4-0',
                // redirect: '/solutions/govment/smartCity',
                // component: resolve => {
                //     require(['@/views/solutions/govment/index.vue'], resolve);
                // },
                // children: [{
                path: '/solutions/govment/smartCity/',
                name: '6-4-0-0',
                component: resolve => {
                    require(['@/views/solutions/govment/smartCity.vue'], resolve);
                },
                // }, ],
                // component: () => import('@/views/solutions/govment/index.vue'),
                // }, ],
            },
            //新闻中心
            {
                path: '/news',
                name: '3',
                component: resolve => {
                    require(['@/views/news/index.vue'], resolve);
                },
            },
            //软硬一体
            {
                path: '/hardware/:id',
                name: '6',
                component: resolve => {
                    require(['@/views/prodservices/hardware.vue'], resolve);
                },
            },
            //软件平台
            {
                path: '/software',
                name: '7',
                component: resolve => {
                    require(['@/views/prodservices/software.vue'], resolve);
                },
            },
            //方案咨询
            {
                path: '/counsel',
                name: '8',
                component: resolve => {
                    require(['@/views/counsel/index.vue'], resolve);
                },
            },

            //新闻中心
            {
                path: '/about',
                name: '4',
                component: resolve => {
                    require(['@/views/about/index.vue'], resolve);
                },
            },

            //加入我们
            {
                path: '/joinUs',
                name: '5',
                component: resolve => {
                    require(['@/views/joinUs/index.vue'], resolve);
                },
            },
            //产品服务
            {
                path: '/prod',
                name: '9',
                component: resolve => {
                    require(['@/views/prodservices/index.vue'], resolve);
                },
            },
            //行业解决方案导航
            {
                path: '/solutions',
                name: '10',
                component: resolve => {
                    require(['@/views/solutions/nav.vue'], resolve);
                },
            },
            //联系人
            {
                path: '/contact',
                name: '12',
                component: resolve => {
                    require(['@/views/about/contact.vue'], resolve);
                },
            },
            //test
            {
                path: '/test',
                name: 'test',
                component: resolve => {
                    require(['@/views/test.vue'], resolve);
                },
            },

        ],
    },




    {
        path: '*', // 找不到的路径就走这里
        component: NotFound,
    },
];

const router = new VueRouter({
    routes,
});

export default router;