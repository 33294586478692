<template>
    <div class="homeview">
        <el-carousel class="b1">
            <el-carousel-item v-for="(item,i) in banners" :key="i">
                <div class="banner" :style="'background: url('+item.imgUrl+'); background-position: center; background-repeat: no-repeat;background-size: cover;'">
                    <div class="txt">
                        <div>{{ item.title }}</div>
                        <div>{{ item.content }}</div>
                    </div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <el-carousel class="b2">
            <el-carousel-item>
                <img class="devimg" src="~@/assets/images/home/1.png" alt style="width:100%;" />
            </el-carousel-item>
            <el-carousel-item>
                <img class="devimg" src="~@/assets/images/home/2.png" alt style="width:100%;" />
            </el-carousel-item>
            <el-carousel-item>
                <img class="devimg" src="~@/assets/images/home/3.png" alt style="width:100%;" />
            </el-carousel-item>
        </el-carousel>
        <SubContent h1class="font-n" h2class="m-1 color-gray minih2" h1="数据创造价值，AI改变生活" h2="国内领先的、以数据治理和智能制造为发展主线的高科技公司">
            <div class="datarow" style="display:none;">
                <div class="introduce-item">
                    <div class="color-blue font-n">
                        <span class="js-odoo odoo"></span>
                        <span class="font-ss">个</span>
                    </div>
                    <div>先后为全国公安部署1000多个行业平台&nbsp;&nbsp;&nbsp;</div>
                </div>
                <div class="introduce-item">
                    <div class="color-blue font-n">
                        <span class="js-odoo1 odoo"></span>
                        <span class="font-ss">万</span>
                    </div>
                    <div>物联网终端数据采集路数超200万路&nbsp;&nbsp;&nbsp;&nbsp;</div>
                </div>
                <div class="introduce-item">
                    <div class="color-blue font-n">
                        <span class="js-odoo2 odoo"></span>
                        <span class="font-ss">次</span>
                    </div>
                    <div>每年完成超过3000次的专业应急/重大保障任务</div>
                </div>
                <div class="introduce-item">
                    <div class="color-blue font-n">
                        <span class="js-odoo3 odoo"></span>
                        <span class="font-ss">万</span>
                    </div>
                    <div>协助公安侦破案件超过20万起&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                </div>
            </div>
            <router-link to="/about">
                <el-button type="info" round>了解更多</el-button>
            </router-link>
        </SubContent>
        <SubContent class="lingyu" h1class="font-n color-white" h2class="m-1 mb-2 color-bluegray" h1="业务领域" h2="量身定制 满足各行业不同阶段的大数据需求">
            <el-button class="btn btn-left" icon="el-icon-arrow-left" @click="prevPage"></el-button>
            <el-button class="btn btn-right" icon="el-icon-arrow-right" @click="nextPage"></el-button>
            <div class="accordion" ref="accordion">
                <div class="lingyu-item" :class="lingyu.style" @click="mouseover(lingyu)" v-for="(lingyu,i) in solutionType" :key="i" :style="'background: url('+lingyu.imgUrl+'); background-position: center; background-repeat: no-repeat;'">
                    <img :src="lingyu.icon" alt />
                    <!-- <div class="line-bottom"></div> -->
                    <div class="title">{{lingyu.title}} </div>
                    <div class="desc">
                        <div></div>
                        <div class="desc-item" v-for="(child,idx) in lingyu.list">
                            <router-link :to="'/solutions/govment/smartCity/?id='+child.id">{{child.headTitle}}</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <template v-slot>
      </template>-->
        </SubContent>
        <SubContent class="prodshow" h1class="font-n" h2class="m-1 color-gray product" h1="产品展示" h2="面向公共安全实战需要，以视频图像技术为核心，通过研究大数据、人工智能实现空间感知与视频感知的结合，并借助可溯源、可贯通、可研判的数据治理能力，打造多维数据融合、应用场景有序的大数据应用与智能物联网产品。">
            <el-tabs v-model="activeName" class="tabs-2">
                <el-tab-pane label="软件平台" name="first">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="12" :md="8" v-for="(item,i) in softwares" :key="i">
                            <router-link :to="'/software/?id='+item.id">
                                <el-card :body-style="{ padding: '0px' }">
                                    <div class="image" :style="'background: url('+item.headImgUrl+') no-repeat center center;background-size:cover;'"></div>
                                    <div style="padding: 14px;">
                                        <span style="white-space: nowrap;">{{item.name}}</span>
                                    </div>
                                </el-card>
                            </router-link>
                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="软硬一体" name="second">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="12" :md="6" v-for="(item,i) in hwsn" :key="i">
                            <router-link :to="'/hardware/'+item.level">
                                <el-card :body-style="{ padding: '0px' }">
                                    <div class="image" :style="'background: url('+item.imgUrl+') no-repeat center center;background-size:cover;'"></div>
                                </el-card>
                                <div style="padding: 14px;color:#000;">
                                    <span>{{item.title}}</span>
                                </div>
                            </router-link>
                        </el-col>
                    </el-row>
                    <router-link to="/hardware/1">
                        <el-button type="info" round>了解更多</el-button>
                    </router-link>
                </el-tab-pane>
            </el-tabs>
        </SubContent>
        <SubContent class="news" h1class="font-n color-white" h2class="m-1 color-bluegray" h1="新闻中心" h2="这里有最新的公司动态与你分享">
            <div class="layout-h-center news-container">
                <div :span="8" v-for="(item,i) in news" :key="i">
                    <a :href="item.url" target="_blank">
                        <div class="item">
                            <div class="time">{{item.create_time}}</div>
                            <img :src="item.thumb_url" alt />
                            <div class="title">{{item.title}}</div>
                            <div class="text-2 desc">{{item.digest}}</div>
                        </div>
                    </a>
                </div>
            </div>
        </SubContent>
    </div>
</template>
<script>
const odoo = require('@/assets/lib/odoo')
import bounty from "bounty";

import SubContent from '@/components/SubContent.vue'
import { getBanners, getHardwareType, getSoftwareType, getSolutions, getHardwares, getArticles } from '@/views/api'
export default {
    name: 'HomeView',
    metaInfo: {
        title: "天翼网络",
        meta: [
            { name: "referrer", content: "never" },
        ],
    },

    components: {
        SubContent
    },
    computed: {

    },

    data() {
        return {
            solutionType: [],
            onOver: 0,
            activeIndex: 0,
            isFirstPage: true, // 业务领域第一页
            activeName: "first",
            banners: [],
            solutions: [],
            softwares: [],
            hardwares: [],
            hws: [],
            hwsn: [{
                title: '警务通',
                level: '7',
                imgUrl: require("@/assets/images/hw/7.png")
            }, {
                title: '天鹰',
                level: '1',
                imgUrl: require("@/assets/images/hw/1.png")
            }, {
                title: '天坛',
                level: '2',
                imgUrl: require("@/assets/images/hw/2.png")
            }, {
                title: '天蝎',
                level: '3',
                imgUrl: require("@/assets/images/hw/3.png")
            }, {
                title: '天箭',
                level: '4',
                imgUrl: require("@/assets/images/hw/4.png")
            }, {
                title: '双鱼',
                level: '5',
                imgUrl: require("@/assets/images/hw/5.png")
            }, {
                title: '双子',
                level: '6',
                imgUrl: require("@/assets/images/hw/6.png")
            }, ],
            news: [],
            lingyus: [{
                    title: '智慧城市',
                    desc: '致力于以大数据为核心业务，不断完善社会安全防控体系的建设，竭力为社会创造更安全、便捷、和谐的生活环境。',
                    icon: require("@/assets/images/智慧政务图标.png"),
                    imgUrl: require("@/assets/images/智慧政务.jpg")
                },
                {
                    title: '智慧交通',
                    icon: require("@/assets/images/智慧交通图标.png"),
                    desc: '致力于以大数据为核心业务，不断完善社会安全防控体系的建设，竭力为社会创造更安全、便捷、和谐的生活环境。',
                    imgUrl: require("@/assets/images/智慧交通.jpg")
                },
                {
                    title: '智慧警务',
                    icon: require("@/assets/images/智慧警务图标.png"),
                    desc: '致力于以大数据为核心业务，不断完善社会安全防控体系的建设，竭力为社会创造更安全、便捷、和谐的生活环境。',
                    imgUrl: require("@/assets/images/智慧警务.jpg")
                },
                // {
                //     title: '智慧能源',
                //     icon: require("@/assets/images/智慧园区图标.png"),
                //     desc: '致力于以大数据为核心业务，不断完善社会安全防控体系的建设，竭力为社会创造更安全、便捷、和谐的生活环境。',
                //     imgUrl: require("@/assets/images/智慧园区.jpg")
                // },
                {
                    title: '智慧教育',
                    icon: require("@/assets/images/智慧教育图标.png"),
                    desc: '致力于以大数据为核心业务，不断完善社会安全防控体系的建设，竭力为社会创造更安全、便捷、和谐的生活环境。',
                    imgUrl: require("@/assets/images/智慧教育.jpg")
                },
                {
                    title: '智慧金融',
                    icon: require("@/assets/images/智慧金融图标.png"),
                    desc: '致力于以大数据为核心业务，不断完善社会安全防控体系的建设，竭力为社会创造更安全、便捷、和谐的生活环境。',
                    imgUrl: require("@/assets/images/智慧金融.jpg")
                },
                {
                    title: '智慧维护',
                    icon: require("@/assets/images/智慧维护图标.png"),
                    desc: '致力于以大数据为核心业务，不断完善社会安全防控体系的建设，竭力为社会创造更安全、便捷、和谐的生活环境。',
                    imgUrl: require("@/assets/images/智慧维护.jpg")
                },
                {
                    title: '智慧能源',
                    icon: require("@/assets/images/卫星互联网图标.png"),
                    desc: '致力于以大数据为核心业务，不断完善社会安全防控体系的建设，竭力为社会创造更安全、便捷、和谐的生活环境。',
                    imgUrl: require("@/assets/images/卫星互联网.jpg")
                },
            ],
        };
    },
    async created() {
        this.banners = await getBanners()
        this.startScrollNumber();

        // this.banners = this.banners.splice(0, 1)
        this.solutions = await getSolutions()
        this.hardwares = await getHardwares()
        // this.hardwares = this.hardwares.slice(0, 4)
        this.hardwares.forEach(e => {
            let exist = this.hws.find(ee => ee.level == e.level)
            if (!exist) this.hws.push(e)

        })

        this.softwares = await getSoftwareType()
        // this.softwares = this.softwares.slice(0, 3)
        this.news = await getArticles({ pageNum: 1 })
        this.news = this.news.slice(0, 3)
        this.setSt()


    },
    methods: {
        setSt() {
            let types = []

            this.solutions.forEach(e => {
                let type = types.find(t => t.title == e.enname)
                let imgArr = this.lingyus.find(t => t.title == e.enname)
                if (!type) types.push({ title: e.enname, list: [e], imgUrl: imgArr.imgUrl, icon: imgArr.icon })
                else type.list.push(e)


            })
            this.solutionType = types;
        },
        mouseover(item) {
            if (this.onOver == 0) {
                this.onOver = 1
                console.log('mouseover')
                this.solutionType.forEach(e => {
                    e.style = 'mouseout'
                })
                console.log(this.solutionType)

                item.style = 'mouseover'
                console.log(this.solutionType)
                this.solutionType = [...this.solutionType]
                this.$refs.accordion.style.transform = 'translateX(0%)'
            } else {
                this.onOver = 0
                this.solutionType.forEach(e => {
                    e.style = ''
                })
                this.solutionType = [...this.solutionType]

            }





        },

        startScrollNumber() {
            // 开始数字滚动
            console.log('startScrollNumber')

            bounty({ el: ".js-odoo", value: "1,000+" });
            bounty({ el: ".js-odoo1", value: "200+" });
            bounty({ el: ".js-odoo2", value: "3,000+" });
            bounty({ el: ".js-odoo3", value: "20+" });


            console.log('endScrollNumber')
        },
        nextPage() {
            if (this.isFirstPage) {
                this.$refs.accordion.style.transform = 'translateX(-50%)'
                this.isFirstPage = false
            }
            this.solutionType.forEach(e => {
                e.style = ''
            })
            this.solutionType = [...this.solutionType]
        },
        prevPage() {
            this.solutionType.forEach(e => {
                e.style = ''
            })
            this.solutionType = [...this.solutionType]
            if (!this.isFirstPage) {
                this.$refs.accordion.style.transform = 'translateX(0%)'
                this.isFirstPage = true
            }

        }
    },
    mounted() {

    }

}
</script>
<style lang="less">
@import '../assets/css/odoo.less';

.homeview {
    .el-carousel__container {
        height: 538px;
    }

    .b1 {
        display: block;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .b2 {
        display: none;

        @media screen and (max-width: 768px) {
            display: block;
        }
    }


    @media screen and (max-width: 768px) {
        .minih2 {
            font-size: 11px;
        }

        .el-carousel__container {
            height: 215px !important;

            .banner {
                .txt {
                    padding: 40px 40px;
                }
            }
        }

        .sub-content {
            div:nth-child(1) {
                font-size: 18px;
            }
        }

        .datarow {
            display: block;
            height: auto;

            .introduce-item {
                border-bottom: 1px solid #eee;
            }
        }

        .accordionx {
            width: 240%;

            .lingyu-item {
                width: 30%;
            }
        }

        .prodshow {
            margin: 0 20px 25px;
        }

        .news {
            height: auto;
        }

        .news-container {
            .layout-container();
        }

        .news-container>div+div {
            margin: 10px;
        }

        .banner {
            .txt {
                div:nth-child(1) {
                    font-size: 36px;

                }

                div:nth-child(2) {
                    font-size: 18px;

                }
            }
        }
    }
}




.m-1 {
    margin: 30px 0;
    line-height: 25px;
}

.banner {
    color: white;
    height: 100%;
    text-align: left;

    .txt {
        padding: 120px 230px;


        div:nth-child(1) {
            font-size: 66px;
            .font-weight-bold();
        }

        div:nth-child(2) {
            letter-spacing: 5px;
            font-size: 20px;
            margin: 5px 0 30px;
        }

        .el-button {
            border-radius: 0;
            padding: 5px 20px;
            background-color: @color-btn-primary;
            border-color: @color-btn-primary;
        }
    }
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.sub-content {
    padding: 25px 0;
}

.datarow {
    height: 150px;
    display: flex;
    margin: 0 auto 40px auto;
    width: 80%;
    border: 1px solid #dae1ea;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);

    .introduce-item {
        flex: 1;
        .layout-v-center();
        align-items: flex-start;
        padding: 20px;

        .odoo1 {
            fill: red !important;
        }

        div:nth-child(1) {
            color: red;
            .font-n();
        }

        div:nth-child(2) {
            .font-weight-bold();
            font-size: 14px;
            word-break: keep-all;
            text-align: left;
            .color-deep-gray();
        }
    }

    .introduce-item+.introduce-item {
        border-left: 1px solid #eee;
    }
}

// 业务领域———手风琴容器
.lingyu {
    background-color: #0c1525;
    position: relative;

    &:hover .btn {
        background-color: rgb(0, 0, 0) !important;
        color: #fff;
    }

    .btn {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        position: absolute;
        top: 60%;
        transform: translateY(-40%);
        z-index: 400;
        color: #fff !important;
        .layout-h-center();
        background-color: rgba(0, 0, 0, 0.6) !important;
    }

    .btn-left {
        left: 10px;
    }

    .btn-right {
        right: 10px;
    }
}

.accordion {
    display: flex;
    width: 200%;
    height: calc((430 / 1080) * 100vh);
    color: white !important;
    transition: transform 300ms ease-in-out;

    .mouseover {
        @media screen and (max-width: 768px) {
            width: 50% !important;

            .title {
                padding-top: 0px;
                transition: all 300ms ease-out;
            }

            .line-bottom {
                transform: translateY(110px);
                width: 70px;
                height: 4px;
                transition: all 300ms ease-out;
            }

            img {
                display: none;
                transition: all 1500ms ease;
            }

            .desc {
                padding-top: 40px;
                display: block !important;
                flex: 1;

                &-item {
                    font-size: 14px;
                    padding: 5px 0;
                }
            }

        }
    }

    .mouseout {
        @media screen and (max-width: 768px) {
            width: 0% !important;
            display: none !important;
        }
    }


    .lingyu-item {
        // flex: 1;
        width: 30%;
        position: relative;
        cursor: pointer;
        transition: all 500ms;
        .layout-v-center();
        align-items: center;
        background-size: 100%;
        height: 380px;

        // width: 150px;
        .line-bottom {
            // 蓝色分界线
            width: 20px;
            height: 2px;
            margin: 15px 0;
            background-color: @color-text-blue;
        }

        .desc {
            .font-sss();
            display: none;
        }

        .title {
            .font-ss();
        }

        img {
            display: block;
            width: 40px;
            height: 40px;
        }

        &:hover {
            @media screen and (min-width: 768px) {
                // 业务领域hover效果
                width: 60%;

                .title {
                    padding-top: 40px;
                    transition: all 300ms ease-out;
                }

                .line-bottom {
                    transform: translateY(110px);
                    width: 70px;
                    height: 4px;
                    transition: all 300ms ease-out;
                }

                img {
                    display: none;
                    transition: all 1500ms ease;
                }

                .desc {
                    padding-top: 40px;
                    display: block;
                    flex: 1;

                    &-item {
                        font-size: 14px;
                        padding: 5px 0;
                    }
                }
            }


        }
    }
}

.prodshow {
    margin: 0 200px 25px;

    .el-tabs__nav {
        height: 50px;
    }

    .el-tabs__header {
        margin: 15px;
    }

    .el-tabs__nav-wrap::after {
        height: 0;
    }

    .el-row {
        padding: 25px 0;
    }

    .image {
        width: 100%;
        height: 193px;
        background-size: cover;
    }

    .el-tabs__nav-scroll {
        .layout-h-center();
    }

    .el-tabs__active-bar {
        display: none;
    }

    .el-tabs__item {
        .el-tabs__nav-scroll {
            overflow: visible;
            height: 100%;
        }

        width: 150px;
        padding: 0;
        text-align: center;
        position: relative;
        margin: 0 10px;
        border: 1px solid #ddd;

        &.is-active {
            color: white;
            background-color: @color-btn-primary;
            position: relative;

            &::after {
                content: '';
                width: 10px;
                height: 10px;
                border: 1px solid;
                position: absolute;
                border-color: @color-btn-primary @color-btn-primary transparent transparent;
                top: 31px;
                background: @color-btn-primary;
                right: calc(50% - 5px);
                transform: rotate(45deg);
                z-index: 3;
            }
        }
    }
}

.news {
    height: calc((617 / 1080) * 100vh);
    background: url('http://www.tievd.com/images/login_33.png') no-repeat center center;
    background-size: cover;
    .layout-flex();
    flex-direction: column;

    &-container {
        width: 100%;
        flex: 1;

        &>div+div {
            margin-left: 100px;
        }
    }

    .item {
        background: url('../assets/images/newsbg.png') no-repeat center center;
        background-size: cover;
        width: 369px;
        height: 360px;
        box-sizing: border-box;
        padding: 0 33px 26px 33px;

        .time {
            color: white;
            font-size: 18px;
            padding: 15px;
        }

        img {
            margin: 21px 0 20px;
            display: block;
            width: 100%;
        }

        .title {
            font-size: 22px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #333333;
            line-height: 30px;
            text-align: left;
        }

        .desc {
            font-size: 14px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            color: #666666;
            line-height: 22px;
            text-align: left;
        }
    }
}
</style>