import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'; // 引入vue-axios

import Meta from "vue-meta";
Vue.use(Meta);

router.afterEach(() => {
    window.scrollTo(0, 0);
})


axios.defaults.baseURL = '/wxOn'
axios.defaults.headers = {
    'Content-Type': 'application/x-www-form-urlencoded'
}
Vue.use(VueAxios, axios); //使用
Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')