<template>
    <div class="about">
        <h1>404</h1>
    </div>
</template>
<script>
export default {
    name: 'NotFound',

}
</script>