<template>
    <div>
        <div class="rnav">
            <div @click="showcs=true">
                <span class="icon iconfont">&#xe69f;</span>
                在线咨询
            </div>
            <div>
                <a href="https://map.baidu.com/poi/%E5%A4%A9%E7%BF%BC%E7%BD%91%E7%BB%9C%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@11582384.135,3564492.92,13z?uid=74fb4bc38a4769ccf70e773c&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&querytype=detailConInfo&da_src=shareurl" target="_blank">
                    <span class="icon iconfont">&#xe641;</span>
                    公司地址
                </a>
            </div>
            <el-popover :visible-arrow="false" placement="left-start" title="" width="200" trigger="hover">
                <div slot="reference">
                    <span class="icon iconfont">&#xe669;</span>
                    售后服务
                </div>
                <div>
                    <div>
                        028-85156002
                    </div>
                    <div>
                        028-85127324
                    </div>
                </div>
            </el-popover>
            <el-popover :visible-arrow="false" placement="left-start" title="" width="200" trigger="hover" class="qrcodebg">
                <img src="@/assets/images/qrcode.jpg" alt="" style="width:100%;height:100%;">
                <div slot="reference">
                    <span class="icon iconfont">&#xe65f;</span>
                    企业微信
                </div>
            </el-popover>
        </div>
        <el-dialog title="智能客服" :visible.sync="showcs" :before-close="beforeClose" class="customerservice">
            <div class="talk_con">
                <div class="talk_show" id="words">
                    <div :class="[(i.person=='A')?'atalk':'btalk']" v-for="i in list1"><span>{{i.say}}</span></div>
                    <!-- <div class="btalk"><span>B说：还没呢，你呢？</span></div> -->
                </div>
                <div style="display: flex;" class="questions">
                    <div v-for="item in  tags" :key="item" @click="quickask(item)">{{item}}</div>
                </div>
                <div class="input">
                    <input type="text" v-model="text1" class="inputarea">
                    <el-button type="primary" style="margin-bottom: 10px;" @click="submit">发送</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import "@/assets/icon/iconfont/iconfont.css"

import { getTag, getAnswer } from '@/views/api'

export default {
    name: "RNav",
    watch: {
        'list1': 'scrollToBottom'
    },
    data() {
        return {
            showcs: false,
            list1: [
                // { person: 'A', say: '吃饭了吗？' },
                // { person: 'B', say: '还没呢，你呢？' },
            ],

            text1: '',
            tags: [],
        }
    },
    methods: {
        scrollToBottom: function() {

            this.$nextTick(() => {

                var div = document.getElementById('words')

                div.scrollTop = div.scrollHeight

            })

        },
        beforeClose() {
            this.showcs = false
        },
        async quickask(tag) {
            await this.ask(tag)
        },
        async submit() {
            await this.ask(this.text1)
            this.text1 = ""
            console.log(this.list1)
        },

        async ask(question) {

            this.list1.push({ person: 'A', say: question })
            let rsp = await getAnswer({ tag: question })
            this.list1.push({ person: 'B', say: rsp[0].answer })
            console.log(this.list1)
        }

    },
    async created() {
        this.tags = await getTag()
    }
}
</script>
<style lang="less">
.customerservice {
    .input {
        display: flex;
        padding: 10px;

        input {
            flex-grow: 20;
            margin-right: 10px;
            border: 1px solid #eee;
            height: 40px;
        }
    }

    .questions {
        padding: 10px;
        background-color: #d9d9d9;

        div {
            cursor: pointer;
            background-color: white;
            border-radius: 20px;
            padding: 5px;
            margin: 0 10px;

        }
    }

    .el-dialog__header {
        background-color: #236dff !important;

        .el-dialog__title {
            color: white !important;
        }

        .el-icon-close {
            color: #000;
        }

    }

    .el-dialog__body {
        padding: 0 !important;
    }

    .talk_con {
        width: 100%;
        background: #f9f9f9;
    }

    .talk_show {
        width: 100%;
        height: 320px;
        background: #fff;
        margin: 10px auto 0;
        overflow: auto;

    }

    .talk_input {
        width: 580px;
        margin: 10px auto 0;
    }

    .whotalk {
        width: 80px;
        height: 30px;
        float: left;
        outline: none;
    }

    .talk_word {
        width: 420px;
        height: 26px;
        padding: 0px;
        float: left;
        margin-left: 10px;
        outline: none;
        text-indent: 10px;
    }

    .talk_sub {
        width: 56px;
        height: 30px;
        float: left;
        margin-left: 10px;
    }

    .atalk {
        margin: 10px;
        text-align: left;
    }

    .atalk span {
        display: inline-block;
        background: #0181cc;
        border-radius: 10px;
        color: #fff;
        padding: 5px 10px;
    }

    .btalk {
        margin: 10px;
        text-align: right;
        white-space: pre-wrap;
    }

    .btalk span {
        display: inline-block;
        background: #ef8201;
        border-radius: 10px;
        color: #fff;
        text-align: left;
        padding: 5px 10px;
    }
}


.rnav {
    .layout-v-center();
    width: 40px;
    bottom: 200px;
    position: fixed;
    right: 10px;
    transition: bottom ease .3s;
    top: 50%;
    z-index: 3;
    cursor: pointer;
    font-size: 12px;

    @media screen and (max-width: 768px) {
        display: none;
    }

    div {
        border-radius: 3px;
        margin: 2px;
        padding: 12px 6px;
        color: white;
        font-weight: bold;
        background-color: #4289dd;

        &:hover {
            background-color: #ff7800;
        }

        .icon {
            display: block;
            font-size: 20px;
            margin-bottom: 5px;
        }
    }
}
</style>