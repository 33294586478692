<template>
    <div class="header">
        <img src="~@/assets/l1.png" alt style="width:50px;height:53px;" />
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" background-color="#fff" text-color="#666" active-text-color="#0E57A2">
            <el-menu-item index="/homeView">首页</el-menu-item>
            <el-submenu index="2">
                <template slot="title">产品服务</template>
                <el-submenu index="1-4">
                    <template slot="title">软硬一体</template>
                    <el-menu-item :index="'/hardware/' + item.id" v-for="(item, h_index) in hardwares">{{ item.name }}</el-menu-item>
                </el-submenu>
                <el-submenu index="2-4">
                    <template slot="title">软件平台</template>
                    <el-menu-item :index="'/software/'+item.id" v-for="(item, s_index) in softwares">{{ item.name }}</el-menu-item>
                </el-submenu>
            </el-submenu>
            <el-submenu index="6">
                <template slot="title">行业解决方案</template>
                <el-submenu :index="'6-4-' + so_index" v-for="(item, so_index) in solutionType">
                    <template slot="title">{{ item.name }}</template>
                    <el-menu-item :index="'/solutions/govment/smartCity/' + itm.id" v-for="(itm, idx) in item.list">{{ itm.name }}</el-menu-item>
                </el-submenu>
            </el-submenu>
            <el-menu-item index="/news">新闻资讯</el-menu-item>
            <el-menu-item index="/about">关于天翼</el-menu-item>
            <el-menu-item index="/joinUs">加入我们</el-menu-item>
        </el-menu>
    </div>
</template>
<script>
import { getHardwareType, getSoftwareType, getSolutions } from '@/views/api';
export default {
    name: 'TievdHeader',
    props: {
        msg: String,
    },
    computed: {
        solutionType: function() {
            let types = [];
            this.solutions.forEach(e => {
                let type = types.find(t => t.name == e.enname);
                if (!type) types.push({ name: e.enname, list: [e] });
                else type.list.push(e);
            });
            return types;
        },
    },
    data() {
        return {
            activeIndex: '',
            softwares: [],
            hardwares: [],
            solutions: [],
        };
    },
    watch: {
        $route() {
            this.setCurrentRoute()
        }
    },
    async created() {
        this.hardwares = await getHardwareType();
        this.softwares = await getSoftwareType();
        this.solutions = await getSolutions();
        this.setCurrentRoute()
    },

    methods: {
        getValue(url) {
            let site = url.lastIndexOf("\/");
            return url.substring(site + 1, url.length);
        },
        setCurrentRoute() {
            this.activeIndex = this.$route.name //关键   通过他就可以监听到当前路由状态并激活当前菜单
        },
        handleSelect(key, keyPath) {
            this.activeIndex = key
            console.log(key, keyPath);
            if (key.indexOf('/solutions/govment/smartCity') !== -1) {
                this.$router.push({
                    path: '/solutions/govment/smartCity/',
                    query: {
                        id: this.getValue(key)
                    }
                });
                return
            }
            if (key.indexOf('/software') !== -1) {
                this.$router.push({
                    path: '/software/',
                    query: {
                        id: this.getValue(key)
                    }
                });
                return
            }
            this.$router.push({
                path: key,
                replace: true
            });
        },
    },
};
</script>
<style lang="less">
@import '../assets/css/el-menu.less';
</style>
<style lang="less" scoped>
.header {
    display: flex;
    margin: 0 160px 0 70px;
    justify-content: space-between;
    align-items: center;

    img {
        width: 116px;
        height: 30px;
    }

    .el-menu.el-menu--horizontal {
        border: 0;
    }
}
</style>