<template>
    <div>
        <el-header>
            <TievdHeader></TievdHeader>
        </el-header>
        <router-view />
        <tievd-footer></tievd-footer>
        <!-- header -->
        <!-- content -->
        <!-- footer -->
        <!-- 右侧 -->
        <rnav />
    </div>
</template>
<script>
// @ is an alias to /src
import TievdHeader from '@/components/TievdHeader.vue'
import TievdFooter from '@/components/TievdFooter.vue'
import rnav from '@/components/rnav.vue'

export default {
    name: 'Main',
    components: {
        TievdHeader,
        TievdFooter,
        rnav,
    },


}
</script>
<style lang="less">
.el-header {
    @media screen and (max-width: 1010px) {
        display: none;
    }
}
</style>