import axios from 'axios'
axios.defaults.baseURL = '/wxOn'
var qs = require('qs');
// 硬件类型
export const getHardwareType = async (params) => {
    let { data: { data } } = await axios.post("/getAllHardwareType")
    return data

}

export const getHardwares = async (params) => {
    let { data: { data } } = await axios.post("/getHardwares")
    return data

}


// 软件类型
export const getSoftwareType = async (params) => {
    let { data: { data } } = await axios.post("/getAllLevels")
    return data

}

// 软件平台
export const getSoftwarePlatform = async (id) => {
    let { data: { data } } = await axios.post("/getPlatforms")
    return id > 0 ? data.filter(e => e.level == id) : data

}

// 行业解决方案

export const getSolutions = async (params) => {
    let { data: { data } } = await axios.post("/getAllSolutions", params)
    return data
}


export const getBanners = async (params, type = 1) => {
    let { data: { data } } = await axios.post("/getTheBanners", params)
    let banners = type > 0 ? data.filter(e => e.type == type) : data
    banners.forEach(e => {
        // e.imgUrl = "http://www.tievd.com/" + e.imgUrl;
    })
    return banners;

}




export const getArticles = async (params) => {
    let { data: { data } } = await axios.post("/getArticles", qs.stringify(params))
    data.forEach(e => {
        if (e.title == '翼起跨年 共赴美好')
            e.create_time = '2023年1月1日'
        else if (e.title == '春光灿烂新起点，时不待我展鸿“兔”')
            e.create_time = '2023年1月18日'
        else if (e.title == '恭贺新春   瑞兔呈祥')
            e.create_time = '2023年1月21日'
        else if (e.title == '大年初一｜拜年啦！')
            e.create_time = '2023年1月22日'
        else if (e.title == '开工啦，加油！')
            e.create_time = '2023年2月1日'
        else if (e.title == '热烈欢迎金牛区罗开敏区长一行莅临天翼参观指导')
            e.create_time = '2023年2月10日'
        else if (e.title == '以初心  致匠心')
            e.create_time = '2023年5月1日'
        else if (e.title == '热血青春  翼起向前')
            e.create_time = '2023年5月4日'
        else if (e.title == '放飞梦想 童心同行')
            e.create_time = '2023年6月1日'
        else if (e.title == '一夜知秋意，夏尽待丰收')
            e.create_time = '2023年8月23日'
        else if (e.title == '粽情端午 平安喜乐')
            e.create_time = '2023年6月22日'
        else if (e.title == '露从今日白，不负好光景')
            e.create_time = '2023年9月8日'

         else if (e.title == '金龙贺岁 迎新承福')
            e.create_time = '2024年1月1日'
         else if (e.title == '一封来自成都市公安局武侯分局的感谢信')
            e.create_time = '2023年12月29日'
         else if (e.title == '冬至福至 长安常乐')
            e.create_time = '2023年12月22日'




    })
    // data.forEach(async e => {
    //     let thumb_url = e.thumb_url
    //     debugger
    //     let base64 = await axios.get('http://sci2018.test/api/imgToBase64?img_url=' + thumb_url)
    //     console.log(base64)
    //     debugger
    // })
    // debugger
    return data
}


export const getCases = async (params) => {
    let { data: { data } } = await axios.post("/getCases", qs.stringify(params))
    return data
}

export const getAbout = async (params) => {
    let { data: { data } } = await axios.post("/getAbout", params)
    return data
}

export const getTag = async (params) => {
    let { data: { data } } = await axios.post("/getTag", params)
    return data
}
export const getAnswer = async (params) => {
    let { data: { data } } = await axios.post("/getAnswer", qs.stringify(params))
    return data
}




export const submitCounsel = async (params) => {
    await axios.post("/addBusiness", qs.stringify(params))

}