<template>
    <div class="sub-content">
        <div :class="h1class">{{ h1 }}</div>
        <div :class="h2class">{{ h2 }}</div>
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name: 'SubContent',
        props: {
            h1: String,
            h2: String,
            h1class: String,
            h2class: String,
        },
    };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
    .sub-content {
        padding: 30px 0;
        overflow: hidden; //BFC
    }
    .product {
        width: 60%;
        padding: 10px 0;
        margin: 0 auto;
        text-align: left;
    }
    @media screen and (max-width: 1440px) {
        .product {
            width: 100%;
        }
    }
</style>
