<template>
    <div class="footer">
        <span>四川天翼网络股份有限公司,四川成都 all rights reserved,@2019 备案号:<a class="redirect" href=" http://beian.miit.gov.cn/" target="_blank">蜀ICP备17033213号-1</a></span>
    </div>
</template>
<script>
export default {
    name: "TievdFooter",
}
</script>
<style lang="less" scoped>
.footer {
    .layout-h-center ();
    .color-white();
    background: #000;
    font-size: 12px;
    padding: 30px 20px;

    .redirect {

        &:link,
        &:visited,
        &:active {
            .color-white();
        }

        &:hover {
            color: #d22e3e;
        }
    }
}
</style>